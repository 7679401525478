/* eslint-disable react-hooks/exhaustive-deps */
import './OrderRequirement.css';
import React, { useEffect, useState } from 'react';
import useProfile from 'src/hooks/useProfile';
import { Navigate } from 'react-router';
import Images from 'src/assets/img';
import { OrderRequirementType, PageData } from 'src/api/models';
import { useGetOrderRequirement } from 'src/api/orderRequirementApi';
import OrderRequirementComponent from 'src/components/OrderRequirementComponent/OrderRequirementComponent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import Products from 'src/components/Products/Products';
import Loading from 'src/components/Loading';

const OrderRequirement: React.FC = () => {
  //Value
  const profile = useProfile();
  const SIZE = 20;

  //State
  const [orders, setOrders] = useState<OrderRequirementType[]>([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingFlag, setLoadingFlag] = useState(true);
  //End of state

  //Function
  const getOrderRequirement = useGetOrderRequirement();

  const fetchData = (reset: boolean) => {
    setLoadingFlag(true);
    const cpage = reset ? 1 : page;
    getOrderRequirement(cpage, SIZE).then((r: PageData<OrderRequirementType>) => {
      const nList = reset ? r.items : [...orders, ...r.items];
      setOrders(nList);
      if (nList.length < r.count) {
        setHasMore(true);
        setPage(cpage + 1);
      } else {
        setHasMore(false);
      }
    }).finally(() => setLoadingFlag(false));
  };


  //End of component

  useEffect(() => {
    fetchData(true);
  }, []);

  //Main
  return (profile ?
    <>
      <PageContainer>
        <PageHeader>
          <NavLink to={'/'}>Home</NavLink>{` / `}Order list
        </PageHeader>
        <InfiniteScroll
          height={'fit-content'}
          dataLength={orders.length}
          next={() => fetchData(false)}
          hasMore={hasMore}
          refreshFunction={() => fetchData(true)}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <div className='refresh-loading'>
              &#8595; Pull down to refresh
            </div>
          }
          releaseToRefreshContent={
            <div className='refresh-loading'>
              <Loading color='gray' />
            </div>
          }
          className={`order-requirement-content`}
          loader={
            <div className='refresh-loading'>
              <Loading color='gray' />
            </div>
          }
        >
          {orders.map((value, index) => {
            return (
              <OrderRequirementComponent
                key={`orderrequirement${index}`}
                data={value}
              />
            );
          })}
          {loadingFlag && <div className='refresh-loading'>
            <Loading color='gray' />
          </div>}
          {!loadingFlag && orders.length == 0 &&
            <div className="text-center">
              <img src={Images.empty_list} width="200" height="200" />
              <div><label>Your order is empty</label></div>
              <div><i>"Wishing you all the best!"</i></div>
            </div>}
        </InfiniteScroll>

      </PageContainer >

      <div className='related-products-title'>PRODUCTS</div>
      <Products isHorizontally={true} />
    </>
    : <Navigate to="/auth-screen" />
  );
};

export default OrderRequirement;
