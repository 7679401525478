import { nanoid } from '@reduxjs/toolkit';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  EnumOrderRequirementProgressStatus,
  EnumOrderRequirementProgressStatusTitle,
  OrderRequirementType,
} from 'src/api/models';
import { number2money } from 'src/utils/stringUtils';
import CartItem from '../CartItem/CartItem';
import './OrderRequirementComponent.css';

interface IOrderRequirementComponent {
  data: OrderRequirementType;
}

const OrderRequirementComponent: React.FC<IOrderRequirementComponent> = (props) => {
  //Value
  const { data } = props;
  const navigate = useNavigate();

  //State
  const [totalMoney, setTotalMoney] = useState(0);

  useEffect(() => {
    const subTotal = data.orderRequirementDetails.reduce((total, detail) => total += detail?.product?.price ?? 0 * detail?.quantity ?? 0, 0);
    setTotalMoney(subTotal + data.shipFee ?? 0);
  }, [data.orderRequirementDetails, data.shipFee]);

  //Main
  return (
    <div
      className={`order-requirement-wrapper`}
      onClick={() => {
        const url = `/order-requirement/${data.orderRequirementId}`;
        window.scrollTo(0, 0);
        navigate(url);
      }}
    >
      <div className="od-header">
        <div className="od-header-status">
          <div>{data.receiverFullname}</div>
          {data.progressStatus == EnumOrderRequirementProgressStatus.Waiting ? (
            <div style={{ color: 'gray' }}>
              <i className="fas fa-spinner"></i>&ensp;
              {EnumOrderRequirementProgressStatusTitle.Waiting}
            </div>
          ) : data.progressStatus == EnumOrderRequirementProgressStatus.Sent ? (
            <div style={{ color: 'green' }}>
              <i className="fas fa-shipping-fast"></i>&ensp;
              {EnumOrderRequirementProgressStatusTitle.Sent}
            </div>
          ) : data.progressStatus == EnumOrderRequirementProgressStatus.Error ? (
            <div style={{ color: 'red' }}>
              <i className="fas fa-exclamation"></i>&ensp;
              {EnumOrderRequirementProgressStatusTitle.Error}
            </div>
          ) : null}
        </div>
        {data?.orderRequirementDetails?.length > 0 ? data.orderRequirementDetails.map((detail, index) => {
          return (
            <CartItem
              key={`cartitem${index}`}
              cartItem={{
                productId: detail.productId,
                quantity: detail.quantity,
                option: detail.option,
                key: nanoid()
              }}
              product={detail.product}
              isDisable={true}
            />
          );
        }) : null}
      </div>
      <div className="od-bottom">
        Tổng số tiền:&ensp;
        <div className="od-prd-child-price">
          {number2money(totalMoney)}
        </div>
      </div>
    </div>
  );
};

export default OrderRequirementComponent;
