import { Configuration } from './contexts/ConfigProvider/ConfigProvider';

const config: Configuration = {
  backendUrl: 'https://be.puganigravio.com/',
  backendWs: 'wss://be.puganigravio.com/pgws',
  clientId: '755261507232-m3n1djtm2vto4qovaff42hck4q4dlak4.apps.googleusercontent.com',
  resourceUrl: 'https://rs.puganigravio.com/',
};

export default config;
